<script setup>


const { locale, locales } = useI18n()
const { loadSettings,content_version,page_loading,cached_data,data } =   useSettings("Header")
 
const { mouse, scroll, uiconfig } = useUIControl();
const storyblokApi = useStoryblokApi()
const route = useRoute();
// let ccolor
// let ccolor
// // if (process.server) {
//   ccolor = useState("ccolor", () => {
//     return "#090";
//   })
//   setTimeout(() => {
//     console.log("done......")
//     ccolor.value = cached_data.value.active_story?.content?.settings_header_color?.color || '#000'
//   },100)
// } else {
//   console.log("done......2")
//   ccolor = useState("ccolor")
// }
// const {data:content} = await useAsyncData('header-menu',()=> loadSettings(),{watch:[locale]})
// console.log("my data",data)
//  console.log(content)
// let ccolor = ref(0)

// let ccolor = ref(null);
let c = cached_data.value.header_color;
// console.log("cached_data.value.header_color:",c)
// let c = col.value;
 const state = reactive({ menu_open: false, hidden: false,color:false,old_path:route.path});
//console.log("state",state)

// updateCC()
watch(() => cached_data.value.header_menu, (val) => {
  // console.log(cached_data.value.header_menu[0])
}, { deep: true });
watch(() => cached_data.value.header_color, (val) => {
  //  console.log(cached_data.value.header_menu)
  c = val;
}, { deep: true });
watch(() => cached_data.value.page_loading, (pval,nval) => { 
  // console.log("page_loading:", pval)
  if (pval && !nval) {
    // console.log("change!")
  }
})
watch(() => cached_data.value.active_story, (val) => {
  updateCC()
  //   console.log("header color:", ccolor.value)
  // console.log(cached_data.value.active_story?.content?.settings_header_color?.color || '#000')
  // state.color = cached_data.value.active_story?.content?.settings_header_color?.color || '#000'
// state

}, { deep: true });
// console.log("header color:", cached_data.value.active_story?.content?.settings_header_color)
watch(()=> route.fullPath, (to) => {
  // console.log("Route change in menu - do hide")
  state.old_path = to;
  hideMenu();
}, { deep: true, immediate: true });
watch(
  () => scroll.value.pstate,
  (val) => {
    // console.log(val)
    if (val.sy > 150 && val.delta > 0) {
      checkHide(true);
    } else if (val.sy < 100 || val.delta < -5) {
      checkHide(false);
    }
  },
  { deep: true }
);

function updateCC() {
  // let scolor = useState("ccolor") 
  c = cached_data.value.header_color;
  //state.color = cached_data.value.active_story?.content?.settings_header_color?.color || '#000'
  // ccolor.value = cached_data.value.active_story?.content?.settings_header_color?.color || '#000'
  // console.log("update color:", ccolor.value)
 // return true
}
function hideMenu() {
  // console.log("hideMenu()")
  state.menu_open = false;
}
function checkHide(do_hide) {
  if (state.menu_open) return;
  if (do_hide && !state.hidden) {
    state.hidden = true;
  } else if (state.hidden && !do_hide) {
    state.hidden = false;
  }
}
function toggleMenu() {
  state.menu_open = !state.menu_open;
  // console.log("toggleMenu()")
  // ccolor.value = state.menu_open ? "#000" : cached_data.value.active_story?.content?.settings_header_color?.color

}
function debugit() {
  // console.log("state", state)
  // console.log("route", route.path)
  
}
// updateCC()
// setTimeout(()=> {updateCC()},50)
//: style = "{ '--c-color': ccolor }
// console.log("DefaultHeader.vue done - ",cached)
// console.log("cached_data.header_menu",cached_data.value.header_menu)
</script>
<template>
  <header class="header" ref="header" @click="debugit"
    :class="{ hide: state.hidden, loading: cached_data.loading }">

    <div ref="header_inner" class="header__inner 2xl:container mx-auto flex justify-between items-center col-span-12"
      :style="{ '--c-color': state.menu_open ? '#000' : (c || '#000') }">
      <NuxtLink :to=" localePath('/')" class="header__logo-wrapper">
        <DefaultLogo />
      </NuxtLink>

      <div class="main-menu-toggle" @click="toggleMenu" :class="{ 'is-open': state.menu_open }">
        <svg id="toggle-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
          <rect class="bar" x="2.95" y="6.5" width="24" height="3" />
          <rect class="bar" x="3" y="13.5" width="24" height="3" />
          <rect class="bar" x="3" y="13.5" width="24" height="3" />
          <rect class="bar" x="3" y="20" width="24" height="3" />
          <rect class="hitbox" width="30" height="30" />
        </svg>
      </div>
    
      <nav class="main-menu flex text-sm" :class="{ 'is-open': state.menu_open }">

        <ul class="flex main-menu__items">
          <li v-for="menu_item in cached_data.header_menu" :key="menu_item._uid" class="item">
            <MenuLink :blok="menu_item" class="l0"></MenuLink>
            <!-- <div class="item__toggle"></div> -->
          </li>
        </ul>
        <div class="menu-spacer"></div>
        <!-- <LanguageSwitcher v-if="content_version == 'draft'" /> -->
        <LanguageSwitcher />
      </nav>
    </div>

  </header>
</template>
<style lang="scss">
.header {
  // background-color: $c-green-40;
  padding: 1rem 2rem;
  svg{
    .bar{
      // transition: --c-color $a-default-ease;
       fill:var(--c-color);
    }
  }
  .l0{

    > a{
  
      // color:var(--c-color);
    }
  }
  // &.faded {
  //   opacity: 0;
  // }
  &.hide {
    .header__inner {
      // background-color: hotpink;
      transform: translateY(-150%);
    }
  }
  .layout--default & {
    // background-color: hotpink ;
    // padding:1rem 2rem;

    height: 80px;
    max-height: 80px;
    // box-sizing:padding-box;
    @include breakpoint-max(lg) {
      // height: 100px;
      max-height: unset;
      padding: 1rem 25px;
    }
    &__inner {
      // display: flex;
      transition: transform $a-default-ease;
      height: 100%;
        // justify-content:space-between;
        // width:100%;
        margin: 0px auto;
      
        max-height: 100%;
      .header__logo-wrapper {
        position: relative;
        z-index: 5;
      }
      .main-menu-toggle {
        width: 30px;
        height: 30px;
        // position: absolute;
        right: 1rem;
        z-index: 5;
        // background-color: $c-purple-100;
        z-index: 25;
        display: none;
        cursor: pointer;
        @include breakpoint-max(lg) {
          display: block;
        }
       
        svg {
          overflow: visible;
          .bar {
            // fill: var(--c-color);
            transform-origin: center center;
            transition: all $a-default-ease;
          }
          .hitbox {
           fill:none;
          }
        }
        &.is-open {
          svg {
            .bar {
              &:nth-child(1) {
                transform: translateY(-6px) scaleX(0);
                opacity: 0;
              }
              &:nth-child(4) {
                transform: translateY(6px) scaleX(0);
                opacity: 0;
              }
              &:nth-child(2) {
                transform: rotate(45deg);
              }
              &:nth-child(3) {
                transform: rotate(-45deg);
              }
              // fill:#fff;
            }
          }
        }
      }
     
      @include breakpoint-max(lg) {
        .main-menu {
          color: #000;
          top: 0px;
          right: 0rem;
          z-index: 20;
          position: absolute;
          background-color: #fff;
          // color: #fff;
          padding: 24px;
          padding-top: 7rem;
          width: 100%;
          // padding-bottom: 3rem;
          font-size: 1.25rem;
          overflow: hidden;
          min-height: 100vh;
          max-height: 100vh;
          width: 100vw;
            overflow-y: scroll;
            // border-top-left-radius: 1rem;
            // border-bottom-left-radius: 1rem;
            z-index: 2;
            padding-bottom: 0px;
            display: none;
            
              flex-direction: column;
              align-items: flex-start;
          @supports (height: 100dvh) {
          
                min-height: 100dvh;
                  max-height: 100dvh;
              
            }
         

          &__items {
            display: flex;
            // display: block;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 1rem;
            > .item {
              // display: block;
              position: relative;
              
              width: 100%;
              > .menu-item {
                margin-bottom: 2rem;
                width: 100%;
                
                > a{
                  // color:var(--c-color);
                }
                &.has-sub-menu {
                  > .menu-item__toggle-icon {
          
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    // position: absolute;
                    // background-color: #000;
                    // right:-20px;
                    // margin-left:1rem;
                    // vertical-align: ;
                    top: 0px;
                    text-align: center;
                    transform-origin: center;
                    transform: rotate(90deg);
                    pointer-events: all;
                    // background-color: aqua;
                    &::after {
                      content: "›";
                      font-size: 2rem;
                      line-height: 25px;
                      // position: absolute;
                    }
                  }
                  &.is-open {
                    > .menu-item__toggle-icon {
                      transform: rotate(-90deg);
                    }
                  }
                }
                > a {
                  font-family: $font-serif;
                  &.router-link-exact-active {
                    margin-right: 0.8rem;
                  }
                  position: relative;
                  margin-right: 1rem;
                  // padding-right:30px;
                  display: inline-block;
                  font-size: 30px;
                  // line-height: 1em;
                }
              }
              // margin-bottom: 0.25rem;
              // margin-bottom: .5rem;
              .menu-item {
                // padding-left:1rem;

                &.has-sub-menu .menu-item.has-sub-menu > a {
                  // background-color: hotpink;
                  font-size: 14px;
                }
                &.has-sub-menu .menu-item.has-sub-menu {
                  // margin-bottom: ;
                }
                a {
                  // display: block;

                  margin-bottom: 0.5rem;
                  &.router-link-exact-active {
                    // text-decoration: underline;
                    &::before {
                      display: none;
                    }
                  }
                }

                .sub-menu {
                  // margin-top: .5rem;
                  display: none;

                  padding: 0px;
                  // margin-left: 1rem;
                  // margin-bottom:1rem;
                  font-size: 1rem;
                  margin-top: 1.5rem;
                  // margin-bottom:.5rem;
                  position: relative;
                  width: 100%;
                  background-color: transparent;
                    border: none;
                  &__item {
                    margin-bottom: 1rem;
                    a {
                      font-size: inherit;
                    }
                  }
                 
                }
                &.is-open {
                  .sub-menu {
                    display: block;
                  }
                }
              }
            }
          }
          .menu-spacer {
            flex-grow: 1;
          }
          .language-switcher {
            padding: 0px;
            display: inline-block;
            position: relative;
            // bottom: -2rem;
            background-color: $c-purple-100;
            width: calc(100% + 4rem);
            border: 0px;
            left: -25px;
            // margin: auto;
            padding: 1.5rem 2rem 1.5rem;
            font-size: 1rem;
            //  border-radius: 1rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            a {
              padding: 0px;
              opacity: 1;
              color: #fff;
              &::after {
                background-color: #fff;
                bottom: -0.5rem;
              }
            }
          }
          // font-size: .85rem;
         

          &.is-open {
            display: flex;
          }
          @include breakpoint(lg) {
            //  display: flex;
            display: flex;
          }
        }
      }
      @include breakpoint(lg) {
        .main-menu {
          &__items {
            > .item{
              // background-color: hotpink;
              > .menu-item > a {
                color:var(--c-color);
              }
            }
            align-items: center;
          }
          .language-switcher{
            color: var(--c-color);
            a{
                color: var(--c-color);
                &::after{
                  background-color:var(--c-color);;
                }
            }
          }
        }
      }
    }
    &__logo-wrapper {
       fill: var(--c-color);
      height: 80%;
      width: 100px;
    }
  }
  .layout--variation & {
    background-color: #fff;
    width: 300px;
    &__inner {
      padding: 2rem;
      // justify-content: space-between;
      flex-direction: column;
      .aj-logo {
        margin-bottom: 2rem;
        position: relative;
        z-index: 5;
      }
      .main-menu {
        margin-left: 2rem;

        > ul {
          flex-direction: column;
          margin-bottom: 1rem;
        }
        // ul{
        //   margin-bottom: 1rem;
        // }

        > ul > li {
          padding-right: 2rem;
        }
      }
    }
  }
}
</style>